// Page Elements

.pageElement {
  width: 100%;
  @include page-element-margin;
}
.pageEl {
  @extend %clearfix;
  > .pageElement{
    float: left;
    clear: both;
  }
}

// Eliminate this if possible
.pageElement{
  .pageElement{
    margin-left: 0;
    margin-right: 0;
  }
}

.hide-tags,
.hide-description {
  .aggregatorElement {
    .tags,
    .description {
      display: none;
    }
  }
}

.minimal-margin {
  .pageElement {
    margin-top: 1px;
    margin-bottom: 1px;
  }
}