// Page Content Container
// This component controls the background behind the site's main content.

.siteContainer {
  min-height: calc(100vh - #{$desktop-nav-height + $account-nav-height-desktop});
  padding: $content-container-padding-top $content-container-padding-right $content-container-padding-bottom $content-container-padding-left;
  @if $enable-full-width-layout != true {
    @include content-area-width;
    @include content-area-container-style;
  }
  
  // Adds homepage specific layout styles
  .home & {
    padding-top: 0;
  }

}
@if $enable-full-width-layout == true {
  #panelTwo{
    &:before{
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      @include content-area-width;
      @include content-area-container-style;
    }
  }
  @if $include-page-title-in-content-area-background == true {
    #yieldContent{
      position: relative;
      padding-top: 15px;
      .sportsPage &{
        padding-top: ($desktop-padding * 2) + 15px;
        padding-bottom: $desktop-padding * 2;
      }
      // remove yeildContent padding on home page
      .home & {
        padding-top: 0px;
      }
    }
    #panelTwo{
      .user_mode & { 
        @include content-container-padding-vert;
      }
      // Adds homepage scecific layout styles
      .home & {
        padding-top: 0;
      }
      .sportsPage &{ padding: 0; }
    }
  } @else {
    #panelTwo{
      position: relative;
      .user_mode & {
        @include content-container-padding-vert;
      }
      // Adds homepage scecific layout styles
      .home & {
        padding-top: 0;
      }
    }
    .sportsPage{
      #yieldContent{
        position: relative;
        @include content-container-padding-vert(2);
      }
      #panelTwo{
        position: unset;
        padding: 0;
      }
    }
  }
}
