/**
 * Hide .container-background-image in .user_mode
 * rely on CSS class container-with-background-image created by _container_background_image.js javascript component
 *
 * Adds CSS class 'container-background-image'
 * 
 * @requires {_container_background_image.js}
 *
 * @example …
 *
 */
.user_mode {
  .container-background-image {
    display: none;
  }
  .container-with-background-image {
    background-size: cover;
    background-position: 0 100%;
  }
}