.sponsors-container {
  padding: 30px 0;

  @media only screen and (min-width: 768px) {
    padding: 35px 0;
  }

  max-width: 100%;
  overflow: hidden;

  .column {
    max-width: 225px;

    @media only screen and (min-width: 768px) {
      max-width: 600px;

    }

    @media only screen and (min-width: 1024px) {
      max-width: 924px;

    }

    @media only screen and (min-width: 1200px) {
      max-width: 900px;

      .home & {
        max-width: 1065px;
      }

    }

  }
  position: relative;
  top: 40px;
  @media only screen and (min-width: 768px) {
    top: 45px;
  }
}