$game-slider-height: 70px;
$game-slider-month-width: 18px;

%games-slider-hover {
  background: $color-primary;
  text-decoration: none;
  * { color: #fff; }
}

.games-slider {
  background-color: #eee;
  font-family: $font-primary;
  margin: 5px 0 15px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  // // :before uses some widths from _page_layouts.scss:218
  // // that file uses the following includes
  // // @include content-area-width;
  // // @include content-container-padding(2)
  // // we actually just want to replicat width and max-width properties from mixin content-area-width
  // // and the internals of mixin content-container-padding(2)
  // position: relative;
  // &:before {
  //   content: '';
  //   position: absolute;
  //   background-color: grey;
  //   // should remain parity with mixin content-area-width values
  //   width: 100%;
  //   max-width: $content-area-width - (($desktop-padding * 2) + ($desktop-padding * 2));
  //   @media screen and (max-width: $breakpoint-sm-max) {
  //     max-width: $content-area-width - (($mobile-padding * 2) + ($mobile-padding * 2));
  //   }
  //   // end parity
  //   height: 100%;
  //   top: 0;
  //   // should remain in parity with mixin content-container-padding(2) but here we are using it to set the left value
  //   left: $desktop-padding * 2;
  //   @media screen and (max-width: $breakpoint-sm-max) {
  //     left: $mobile-padding * 2;
  //   }
  // }

  ul {
    color: #666;
    display: table;
    font-size: 10px;
    list-style: none;
    text-align: center;
  }

  li {
    display: table-cell;

    &:first-child a { border-left-width: 2px; }
    &:last-child a { border-right-width: 2px; }
    &.selected a { @extend %games-slider-hover; }
  }

  a {
    background: #fff;
    box-shadow:
      0 2px #b5b5b5 inset,
      0 -2px #b5b5b5 inset;
    border-color: #b5b5b5;
    border-style: solid;
    border-width: 0 1px;
    box-sizing: border-box;
    color: $color-gray-dk;
    display: block;
    height: $game-slider-height;
    position: relative;
    transition:
      background .25s,
      border .25s,
      box-shadow .25s,
      color .25s;
    vertical-align: middle;
    width: 50px;

    &:hover { @extend %games-slider-hover; }
  }
}

.games-slider-inner { 
  width: 100%;
  display: flex;
  background-color: $color-gray;
 }


.games-slider-group {
  display: table-cell;
  padding-left: $game-slider-month-width;
  position: relative;
}

.games-slider-month {
  background: $color-secondary;
  box-sizing: border-box;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  font-family: $fontTwo;
  height: $game-slider-month-width;
  line-height: $game-slider-month-width - 2px;
  padding: 1px 0 1px 4px;
  position: absolute;
  text-transform: uppercase;
  transform:
    rotatez(-90deg)
    translate(-100%, -100%);
  transform-origin: 0 0;
  white-space: nowrap;
  width: $game-slider-height;
}

.games-slider-date {
  display: block;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

.games-slider-day,
.games-slider-count,
.games-slider-time,
.games-slider-outcome {
  display: block;
  font-family: $font-secondary;
  font-weight: 400;
}

.games-slider-day {
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  padding-top: 2px;
}

.games-slider-count,
.games-slider-time {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  color: $color-gray-md;
}

.games-slider-outcome {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  color: $color-gray-md;
  text-transform: uppercase;
}
