/* Widget - Stat Leader
---------------------------------------------------------------------------------------------------- */
.widget-leader {
  .sportTableLabel { @include sport-table-label; }

  .statTable {
    width: 100%;

    th, td {
      &:nth-child(1) { text-align: center; } // rank column
      &:nth-child(2) { text-align: left; } // name column
      &:nth-child(3) { text-align: center; } // stat column
    }

    th:nth-child(1) { width: 30px; }

    td {
      white-space: nowrap;

      &:nth-child(1) { font-weight: 700; }
    }
  }

  .leaderName {
    display: inline-block;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
  }

  .leader-highlight {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 17px;
    background-color: $color-secondary;
    border-radius: 10px 10px 0px 0px;
  }

  .stats {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 0 0 10px;
    text-align: center;
  }

  .stats-major,
  .stats-minor {
    margin: 0;
    padding: 0;
  }

  .player_photo {
    overflow: hidden;
    height: 76px; // Adds 6 for height of both borders
    border: 3px solid $color-gray-md;
    border-radius: 100%;
    background-color: $color-secondary; // Same grey as default player photo
    img {
      display: block;
      width: 70px;
    }
  }
}
