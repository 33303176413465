// Page Title (display Body Header)
$page-title-full-width: false !default;
$page-title-full-width-text: false !default;
$page-title-container-background: $content-area-background !default;
$page-title-text-color: #333 !default;
$page-title-font-size: 30px !default;
$page-title-text-case: none !default;
$page-title-font-weight: normal !default;
$page-title-text-align: null !default;

#displayBodyHeader {
  position: relative;
  background-color: $page-title-container-background;
  @if $page-title-full-width != true { @include content-area-width; }
  @include page-title-container;
  span {
    display: block;
    color: $color-secondary;
    font-size: $page-title-font-size;
    text-transform: $page-title-text-case;
    font-weight: $page-title-font-weight;
    text-align: $page-title-text-align;
    @if $page-title-full-width-text != true and $page-title-full-width == true { @include content-area-width; }
    bottom: 0;
    left: 0;
    @media screen and (max-width: 1024px) {
      font-size: 36px;
      line-height: 48px;
    }
  }
  &.displayBodyHeader-image {
    height: auto !important;
    text-indent: 0;
    @include page-title-image-container;
    span {
      position: absolute;
      color: $color-white;
      @include page-element-padding;
      @include content-container-padding(2);
      @include page-title-text;
    }
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, black, transparent);
    }
  }
}
