// Global Footer
// Edit mode UI
.custom-footer-extra{
  @include edit_ui_element($padding: 10px);
  &:empty{ display: none; }
  background-color: $footer-bands-background-color;
  text-align: center;
  /**
   * link that wraps the footer logo
   */
  .footerLogoLink {
    display: inline-block;
    margin-top: 50px;
    margin-bottom: 30px;
    /** 
     * footer logo img tag
     */
    .footerLogoImg {
      display: inline-block;
      max-height: 100px;
      max-width: 100px;
    }
  }
  /**
   * footer navigation ul tag
   */
  .footerNavigation {
    li {
      display: inline-block;
      @media screen and (max-width:440px) {
        display: block;
      }
      a {
        font-family: $fontOne;
        font-size: 1rem;
        line-height: $footer-navigation-line-height;
        color: $color-white;
        transition: color $transition-linear;
        padding-left: 27px;
        padding-right: 27px;
        text-transform: uppercase;
        &:hover {
          color: $color-primary;
        }
      }  
    }
  }
}
