/**
 * Adds CSS class 'background-position-right'
 * Adds CSS Class 'home-about-row'
 * @description This CSS
 * @requires {_container_background_image.js}
 * @requires {_container_background_image.scss}
 *
 * For additional related or unrelated background image functionality 
 * @see {_container_background_image_position.js}
 *
 * @example …
 *
 */

/**
 * Operate on Sitebuilder in user mode
 */
.user_mode {
  .background-position-right {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
  }
}

/**
 * This layout container is expected to be a 2/3 1/3 layout container
 * on mobile we can hide second column, expand the 2/3 column to 100%
 */
.user_mode {
  .home-about-row {
    @media screen and (max-width:1330px) {
      background-position: bottom 0% right -20%;
    }
    @media screen and (max-width:1180px) {
      background-position: bottom 0% right -50%;
    }
    @media screen and (max-width:1100px) {
      background-position: bottom 0% right -80%;
    }
    @media screen and (max-width:1080px) {
      background-position: bottom 0% right -150%;
    }
    @media screen and (max-width:1050px) {
      background-position: bottom 0% right -300%;
    }
    @media screen and (max-width:1024px) {
      // using !important to affect transparency on inline background-image 
      background: transparent !important;
      .col-md-8 {
        width: 100%;
        max-width: 100%;
      }
      .col-md-4 {
        display: none;
      }
    }
  }
}

/**
 * No need to operate on custom background image when in edit mode 
 * since this is a dependency of _container_background_image JS and SCSS
 */

