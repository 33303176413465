/**
 * @todo - document this
 */
.slideNavigationLogoLink {
  height: $mobile-nav-height;
  display: flex;
  justify-content: center;
  align-items: center;

  // logo img
  .slideNavigationLogoImg {
    width: $site-logo-width-mobile;
    height: $site-logo-height-mobile;
  }

}
