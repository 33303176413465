#PageSearchBoxMobileNav,
#PageSearchBoxTopNav {
  position: relative;
  padding-right: 31px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 14px;
  input.theme-search-input {
    z-index: 1;
    position: relative;
    border: none;
    background-color: transparent;
    color: $color-white;
    font-weight: 700;
    font-family: 'Lato';
    font-size: 14px;
    letter-spacing: .75px;
    width: 110px;
    padding-right: 5px;
    &:focus {
      outline: none;
    }
  }
  
  // placehodler text color
  input.theme-search-input::placeholder {
    color: $color-white;
  }
  
  // search button
  // hide word Search, replace with magnification icon
  span.theme-search-submit {
    font-size: 0;
    z-index: 0;
    background-color: $color-primary;
    border-radius: 14px;
    height: 31px;
    width: 31px;
    position: absolute;
    right: 0;
    transition: width $transition-linear;
  }
  
  // When input is in focus
  // general sibline selector 
  input.theme-search-input:focus ~ span.theme-search-submit {
    width: 100%;
  }
  
  // magnifying glass icon
  span.theme-search-submit:before {
    content: '\f002';
    font-family: 'FontAwesome';
    color: white;
    z-index: 1;
    font-size: 18px;
    position: absolute;
    height: 31px;
    top: -27px;
    right: 7px;
    .nav-fixed & {
      top: -36px;
    }
  }
}

#PageSearchBoxMobileNav span.theme-search-submit:before {
  top: initial;
}
#PageSearchBoxMobileNav {
  margin-left: 10px;
  margin-right: 15px;
}
#PageSearchBoxMobileNav input.theme-search-input {
  padding-left: 0;
}