/**
 * Hero section
 * requires layout container at top of page with CSS class .hero-section
 * layout container should be one column
 *
 * page element Single Photo requirements:
 * - ...
 *
 * page element Text Block requirements:
 * - ...
 * 
 * Javascript component _hero_section.js applied CSS class 'has-hero-section' to HTML tag
 */
 
 // DOM changes required for elements that live outside of the .hero-section layout container

 // target #topNav for color transition when hero section is present
 // don't transition the nested elements as they change size and cause strange animations
 // CSS class has-hero-section only applied in user mode
 .has-hero-section {
   #topNav {
     background-color: transparent;
     transition: background-color $transition-linear;
    .theme-main-nav {
      background-color: transparent;
    }
  }
  #topNavPlaceholder {
    background-color: transparent;
  }
  &.nav-fixed {
    #topNav {
      background-color: $color-secondary;
    }
  }
  // add black color to mobile navigation
  &.has-mobile-nav {
    #topNav {
      background-color: $color-secondary;
    }
  }
}

//only apply component styles in user_mode
.user_mode {
  // Hero Section
  .hero-section {
    position: relative;
    max-width: 100%;
    padding: 0;
    .has-mobile-nav & {
      margin-top: 0 !important;
    }
    .pageElement {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .column-1.column.col-sm-12.col-md-12 {
      max-width: 100%;
      padding: 0;
      position: relative;
      .hero-text {
        position: absolute;
        left: 50%;
        top: calc(50% + 50px);
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        width: 60%;
        @media screen and (max-width: 500px) {
          width: 80%;
        }
        .has-mobile-nav & {
          top: calc(50%);
        }
        .pageElement {
          > h3:first-child {
            font-size: 60px;
            line-height: 68px;
            color: $color-white;
            @media screen and (max-width: 1090px) {
              font-size: 48px;
              line-height: 54px;
            }
            @media screen and (max-width: 990px) {
              font-size: 36px;
              line-height: 48px;
            }
            @media screen and (max-width: 990px) {
              font-size: 36px;
              line-height: 48px;
            }
            @media screen and (max-width: 720px) {
              font-size: 24px;
              line-height: 32px;
            }
          }
          .text {
            text-align: center;
            p {
              display: inline-block;
              margin: 0px 10px 0px 10px;
              &:first-of-type {
                margin-left: 0px;
              }
              &:last-of-type {
                margin-right: 0px;
              }
            }
            a[name="button"] {
              background-color: $color-white;
              color: $color-primary;
              transition: background-color $transition-linear, color $transition-linear;
              font-family: $fontTwo;
              font-weight: 900;
              font-size: 14px;
              line-height: 26px;
              padding-top: 12px;
              padding-right: 30px;
              padding-bottom: 12px;
              padding-left: 30px;
              border-radius: 10px;
              border: none;
              &:hover {
                background-color: $color-primary;
                color: $color-white;
                text-decoration: none;
              }
              @media screen and (max-width: 1024px){
                font-size: 12px;
                line-height: 26px;
                padding-top: 4px;
                padding-right: 28px;
                padding-bottom: 4px;
                padding-left: 28px;
              }
            }
          }
        }
      }
    }
  }
}