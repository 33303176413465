$sponsor-logo-height: 90px;
// Sponsors Container
.sponsors-container {
  background: #fff;
  padding: 40px 50px;
  .slick-slider {
    .slick-prev:before,
    .slick-next:before {
      color: #ddd;
      font-size: 36px;
      font-weight: 400;
      transition: color $transition-linear;
    }
    .slick-prev:hover:before,
    .slick-next:hover:before {
      color: $color-primary;

    }
    .slick-next:before {
      content: '\f105';
      font-family: FontAwesome;
    }
    .slick-prev:before {
      content: '\f104';
      font-family: FontAwesome;
    }
    .slick-slide {
      .heroPhotoElement {
        img {
          padding: 10px;
          max-height: $sponsor-logo-height;
          max-width: 100%;
          width: auto;
          transition: transform $transition-linear;
        }
      }
      &:hover {
        // background-color: $lt_gray;
        img {
          transform: scale(1.05);
        }
      }
    }
    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100%;
    }
  }
  .column {
    padding: 0;
  }
  .edit_mode & {
    .heroPhotoElement {
      img {
        max-height: $sponsor-logo-height;
        width: auto;
      }
    }
  }
  .has-mobile-nav & {
    img {
      max-height: $sponsor-logo-height;
      width: auto;
    }
  }
}
